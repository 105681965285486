<template>
  <Dialog ref="dialog" :title="title" width="500px" :color="'error'">
    <v-container>
      <div class="text-center" style="margin: 0 auto;">
        <v-card-text v-if="message" class="text-center py-0 py-3 mx-auto">
        <v-col
          v-for="(text, index) in splitMessageLine(message)"
          :key="index"
          :class="isLineBreakStyle(text)"
          >{{ text }}
        </v-col>
      </v-card-text>
      </div>
    </v-container>
    <v-container
      v-if="errorMessageList.length > 0"
      id="scroll-target"
      class="overflow-y-auto mx-auto"
      style="max-height: 30vh; max-width: 90%; border: #ddd 3px solid; "
      wrap
    >
      <v-row v-for="(text, index) in errorItemList" :key="index" class="my-0">
        <v-col>
          {{ text }}
        </v-col>
        <v-col>
          {{ errorMessageList[index] }}
        </v-col>
      </v-row>
    </v-container>
    <template v-slot:footer>
      <v-btn 
        width="30vw"
        height="40px"
        max-width="170px"
        class="next"
        dark 
        @click="goHome()"
      >
        {{ $t('button.goHome') }}
      </v-btn>
    </template>
  </Dialog>
</template>

<script>
import Dialog from '@/components/organisms/agency/Dialog';
import { splitMessageLine } from '@/lib/commonUtil';

export default {
  components: { Dialog },
  data() {
    return {
      title: '',
      message: '',
      errorItemList: [],
      errorMessageList: [],
      breakLineStyle: ['ma-0', 'px-2', 'py-3'],
      noBreakLineStyle: ['ma-0', 'px-2', 'py-0'],
    };
  },
  methods: {
    open(title, message, errorItemList, errorMessageList) {
      (this.title = title),
        (this.message = message),
        (this.errorItemList = errorItemList),
        (this.errorMessageList = errorMessageList),
        this.$refs.dialog.open();
    },
    goHome() {
      this.$router.push('/Home');
    },
    // メッセージを改行で表示
    splitMessageLine(message) {
      return splitMessageLine(message);
    },
    isLineBreakStyle(message) {
      return message ? this.noBreakLineStyle : this.breakLineStyle;
    },
  },
};
</script>
