import axios from '@/apis/axios';
import { convertCamelToSnake } from '@/lib/api';

/**
 * 秘密の質問取得
 */
export const getSecretQuestion = async userDetails => {
  userDetails = convertCamelToSnake(userDetails);
  const response = await axios
    .get('/agencies/noncertifications/secret_questions', {
      params: { ...userDetails },
    })
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

/**
 * パスワード再発行申請
 */
export const forgotPassword = async userDetails => {
  const response = await axios
    .post(`/agencies/noncertifications/forgot_password`, { ...userDetails })
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};
