import axios from '@/apis/axios';
import { getAccessToken } from '@/lib/api';

/**
 * 外部から案件情報の取り込み
 */
export const importExternalContractData = async (externalCode) => {
  const accessToken = await getAccessToken();

  const response = await axios
    .post(`/agencies/externals/contract/import`,
      { externalCode: externalCode },
      {
        headers: {
          'access-token': accessToken,
        },
      }
    )
    .catch(error => {
      console.log(error);
      throw error.response.data;
    });
  return response.data;
};
